import showroom from "../images/showroom-template.png";
import coverpage from "../images/coverpage-contents.png";
import microsite from "../images/micrositedemo.png";
import masterview from "../images/masterview.png";
import streaming from "../images/streaming.png";
import docview from "../images/docview.png";
import customizer from "../images/customizer.png";
// import customizermw from "../images/meetingcustomizer.png"
// import customizermw from "../images/meetingcblue.png"
import customizermw from "../images/meetingcustomzier1.png"
import lobby from "../images/lobby.png";
import views from "../images/viewsss.png";
import agenda from "../images/agendaa.png";
import spaces from "../images/3dspacesss.png";
import lobbyyy from "../images/3dspacesss.png";
import meeting from "../images/meetingtimee.png";



export const masterContent = [
  {
    image: masterview,
    title: "Master View",
    description:
      "Easy overview of your content via our top menu. Navigation is convenient since it resembles a manual or book structure. Videos, presentations, and more in full or normal size.",
  },
  {
    image:streaming,
    title: "Streaming View",
    description:
      "Experience content navigation akin to streaming movies and music. Take full advantage of our streaming view to visually explore your own content accessible through intuitive thumbnails.",
    extraClass: "flex-reverse1",
  },
  {
    image: docview,
    title: "Doc View",
    description:
      "Use this standing format for portrait-based content such as Word documents and standing PDFs. Ideal for reports, contracts, resumes, and other portrait media. Versatile and easily shareable menu. ",
  },
  {
    image: showroom,
    title: "Show Rooms",
    description:
      "Combine 2-20 presentations and videos together. Choose from a variety of presentation and video templates. Use our showrooms for sales meetings, project meetings, training, manuals, and much more.",
    extraClass: "flex-reverse1",
  },
  {
    image: coverpage,
    title: "Cover Pages",
    description:
      "Customize Show Rooms with a Cover Page. Create a more personalized connection with the target audience. Use Cover Pages to give a Show Room a new profile and angle without changing its content. Tailor your Cover Page with visuals and text.",
  },
  {
    image: microsite,
    title: "Microsite",
    description:
      "You can create a Microsite by combining two or more Show Rooms. Related Show Rooms can be presented easily on a stylish Microsite. Ideal for manuals, training, portfolios, and building content systems.",
    extraClass: "flex-reverse1",
  },
  {
    image: customizer,
    title: "Customizer",
    description:
      "Navigate through slides, images, and videos. Create new presentations or add to existing ones. Easy creation, version control, and brand consistency.",
  },
];
export const masterContent1 = [
  {
    image: lobby,
    title: "Login experience",
    description:
      "Log in to experience our video solution - vibrant, welcoming, and with added features like our Lobby for quick access to additional information.",
  },
  {
    image:customizermw,
    title: "Meeting Customizer",
    description:
      "With custom options for conference mode, team meetings, and presentation mode, you can tailor your meetings to perfection.",
    extraClass: "flex-reverse",
  },
  {
    image: views,
    title: "Views",
    description:
      "Why limit views just to a few options? You can choose from a wide range of options that cater to different team sizes and meeting types. Beats digital fatigue. ",
  },
  {
    image: meeting,
    title: "Meeting Timer",
    description:
      "Easily synced with the agenda. Pinpoint who's contributing the most, streamline meeting discipline, and save costs by reducing wasted time. ",
    extraClass: "flex-reverse",
  },
  {
    image: agenda,
    title: "Agenda",
    description:
      "You can save frequently repeated agendas. Accelerate meeting starts and maximize productivity by integrating the Meeting Timer.",
  },
  {
    image: spaces,
    title: "3D Spaces",
    description:
      "Over 150 specially created 3D visualizations for virtual backgrounds. Look professional in all contexts or be playful with more untraditional backgrounds. Real-images created by experienced 3D artists. ",
    extraClass: "flex-reverse",
  },
  {
    image: lobbyyy,
    title: "Lobby",
    description:
      "The Lobby sets the perfect tone for pre or post-meeting networking. Our premium version can be customized to showcase your practical information, ideal for branding and messaging.",
  },
];