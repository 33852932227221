import React from "react";
import * as styles from "./MasterBanner.module.scss";

function MasterBanner({ title, description, button, span, extraClass ,path}) {
  return (
    <div className={`${styles.masterbanner} ${styles[extraClass]}`}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span>{span}</span> - {title}
        </h1>
        <p>{description} </p>
      </div>
    </div>
  );
}
export default MasterBanner;
