import React from "react";
import "./ImageSection.scss";

function ImageSection({title,description,image,extraClass,button,path}){
    return(
        <div className={`image-reusabale-section ${extraClass}`}>
            <img alt="section" src={image}/>
            <div className={"section-content"}>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
        </div>
    );
}
export default ImageSection;